var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "asyent-card",
            {
              attrs: {
                collapsed: "",
                expandable: "",
                title: "Consignment " + (_vm.index + 1),
              },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("asyent-form-display", {
                            attrs: {
                              value: _vm.value2["id"],
                              label: _vm.ae$lng("id"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("asyent-form-display", {
                            attrs: {
                              value: [
                                _vm.value2["idCargoVoyage"]["departurePort"][
                                  "code"
                                ],
                                _vm.value2["idCargoVoyage"]["departurePort"][
                                  "name"
                                ],
                                _vm.value2["idCargoVoyage"]["departurePort"][
                                  "countryName"
                                ],
                              ],
                              label: "Departure Port",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("asyent-form-display", {
                            attrs: {
                              value:
                                _vm.value2["idCargoVoyage"]["departureDate"],
                              label: _vm.ae$lng("departureDate"),
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("asyent-form-display", {
                            attrs: {
                              value:
                                _vm.value2["idCargoVoyage"]["voyageNumber"],
                              label: _vm.ae$lng("voyageNumber"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "asyent-card",
                            {
                              attrs: {
                                flat: "",
                                color: "bgWhite",
                                title: "Carrier",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", md: "12", lg: "12" },
                                    },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value: _vm.value2["carrier"]["id"],
                                          label: _vm.ae$lng("SCAC Id."),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", md: "12", lg: "12" },
                                    },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value: _vm.value2["carrier"]["name"],
                                          label: _vm.ae$lng("Name & Address"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "asyent-card",
                            {
                              attrs: {
                                flat: "",
                                color: "bgWhite",
                                title: "Consignor",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", md: "6", lg: "6" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value: _vm.value2["consignor"]["id"],
                                          label: _vm.ae$lng("Eori No."),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", md: "6", lg: "6" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value:
                                            _vm.value2["consignor"][
                                              "providerRef"
                                            ],
                                          label: _vm.ae$lng("Provider Ref."),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value:
                                            _vm.value2["consignor"]["name"],
                                          label: _vm.ae$lng("Name & Address"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "asyent-card",
                            {
                              attrs: {
                                flat: "",
                                color: "bgWhite",
                                title: "Consignee",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", md: "6", lg: "6" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value: _vm.value2["consignee"]["id"],
                                          label: _vm.ae$lng("Eori No."),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", md: "6", lg: "6" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value:
                                            _vm.value2["consignee"][
                                              "providerRef"
                                            ],
                                          label: _vm.ae$lng("Provider Ref"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value:
                                            _vm.value2["consignee"]["name"],
                                          label: _vm.ae$lng("Name & Address"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "asyent-card",
                            {
                              attrs: {
                                flat: "",
                                color: "bgWhite",
                                title: "Notify Party",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", md: "6", lg: "6" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value:
                                            _vm.value2["notifyParty"]["id"],
                                          label: _vm.ae$lng("Eori No."),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", md: "6", lg: "6" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value:
                                            _vm.value2["notifyParty"][
                                              "providerRef"
                                            ],
                                          label: _vm.ae$lng("Provider Ref"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("asyent-form-display", {
                                        attrs: {
                                          value:
                                            _vm.value2["notifyParty"]["name"],
                                          label: _vm.ae$lng("Name & Address"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }