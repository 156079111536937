var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon,
      },
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (props) {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.rop !== "new"
                          ? _c("asyent-form-info", {
                              attrs: {
                                "view-mode": _vm.viewMode,
                                "no-title": "",
                                "no-maximize": "",
                              },
                              model: {
                                value: _vm.formDataF,
                                callback: function ($$v) {
                                  _vm.formDataF = $$v
                                },
                                expression: "formDataF",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { expandable: "", title: "Reference" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "6" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["instanceId"],
                                        label: "Document ID",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3", md: "3", lg: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("equipmentNumber"),
                                        name: "equipmentNumber",
                                      },
                                      model: {
                                        value: _vm.formDataF["equipmentNumber"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "equipmentNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['equipmentNumber']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3", md: "3", lg: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: _vm.ae$lng("equipmentType"),
                                        name: "equipmentType",
                                      },
                                      model: {
                                        value: _vm.formDataF["equipmentType"],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "equipmentType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['equipmentType']",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Information" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", lg: "12" } },
                              _vm._l(
                                _vm.formDataF["summaryConsignments"],
                                function (summaryConsignment, index) {
                                  return _c(
                                    "v-row",
                                    { key: index },
                                    [
                                      _c("containerSummaryConsignment", {
                                        attrs: {
                                          name: "summaryConsignment.id",
                                          index: index,
                                        },
                                        model: {
                                          value:
                                            _vm.formDataF[
                                              "summaryConsignments"
                                            ][index],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formDataF[
                                                "summaryConsignments"
                                              ],
                                              index,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formDataF['summaryConsignments'][index]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "12" } },
                      [
                        _c("asyent-form-select", {
                          attrs: {
                            "form-errors": _vm.formErrors,
                            "form-rules": _vm.formFieldsRules,
                            "is-disabled-func": _vm.isDisabled,
                            "items-reload": _vm.otherAgenciesReload,
                            props: props,
                            "enable-select-all": "",
                            "item-text": "code",
                            "item-value": "name",
                            label: _vm.ae$lng("otherAgencies"),
                            multiple: "",
                            name: "otherAgencies",
                          },
                          model: {
                            value: _vm.formDataF["otherAgencies"],
                            callback: function ($$v) {
                              _vm.$set(_vm.formDataF, "otherAgencies", $$v)
                            },
                            expression: "formDataF['otherAgencies']",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "6", lg: "6" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Container Selectivity" } },
                          [
                            _c(
                              "v-row",
                              { staticClass: "pb-4" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c("asyent-form-display", {
                                      staticClass: "pa-2",
                                      style: {
                                        "background-color":
                                          _vm.showRiskAsColor("systemChannel"),
                                      },
                                      attrs: {
                                        value: _vm.showRisk("systemChannel"),
                                        label: "System Risk",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c("asyent-form-display", {
                                      staticClass: "pa-2",
                                      style: {
                                        "background-color":
                                          _vm.showRiskAsColor("officerChannel"),
                                      },
                                      attrs: {
                                        value: _vm.showRisk("officerChannel"),
                                        label: "Officer Decision",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.viewMode && !_vm.isDisabled("rerouteAction.details")
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: {
                                  title: _vm.ae$lng("Action"),
                                  color: "cardDefault",
                                  "no-maximize": "",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("asyent-form-select", {
                                          attrs: {
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            "items-reload":
                                              _vm.containerRiskReload,
                                            props: props,
                                            "drop-down": "",
                                            label: _vm.ae$lng("officerRisk"),
                                            name: "rerouteAction.officerRisk",
                                            sortable: "",
                                            "view-mode": _vm.viewMode,
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["rerouteAction"][
                                                "officerRisk"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF["rerouteAction"],
                                                "officerRisk",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['rerouteAction']['officerRisk']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("asyent-form-textarea", {
                                          attrs: {
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            "no-label": "",
                                            label: _vm.ae$lng("Action Details"),
                                            name: "rerouteAction.details",
                                            "view-mode": _vm.viewMode,
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["rerouteAction"][
                                                "details"
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formDataF["rerouteAction"],
                                                "details",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['rerouteAction']['details']",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.actions.length > 0
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: {
                                  title: _vm.ae$lng("Actions"),
                                  extended: "",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-data-table", {
                                          attrs: {
                                            headers: [
                                              {
                                                text: _vm.ae$lng(
                                                  "Action Date/Time"
                                                ),
                                                value: "dateTime",
                                              },
                                              {
                                                text: _vm.ae$lng("Name"),
                                                value: "user.name",
                                              },
                                              {
                                                text: _vm.ae$lng("officerRisk"),
                                                value: "officerRisk",
                                              },
                                              {
                                                text: _vm.ae$lng("details"),
                                                value: "details",
                                              },
                                            ],
                                            items: _vm.actions,
                                            "items-per-page": -1,
                                            "hide-default-footer": "",
                                            "disable-sort": "",
                                            "show-expand": "",
                                            expanded: _vm.expandedActions,
                                            "item-key": "id",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item.dateTime",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.ae$formatDateTime(
                                                            item["dateTime"]
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "expanded-item",
                                                fn: function (ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass: "pa-4",
                                                        attrs: { colspan: "4" },
                                                      },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-card",
                                                                  {
                                                                    attrs: {
                                                                      title: "",
                                                                      "no-title":
                                                                        "",
                                                                      "toc-level": 0,
                                                                      color:
                                                                        "cardDefault",
                                                                      "no-maximize":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-row",
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                cols: "12",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "asyent-form-display",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      item[
                                                                                        "details"
                                                                                      ],
                                                                                    "no-label":
                                                                                      "",
                                                                                    label:
                                                                                      _vm.ae$lng(
                                                                                        "Details"
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c("br"),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c("br"),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Summary Consignments",
                            },
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.summaryConsignmentsTableHeaders,
                                items: _vm.formDataF["summaryConsignments"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.documentId",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "form-display-div" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: _vm.showConsignmentLink(
                                                    item[
                                                      "consignmentInstanceId"
                                                    ]
                                                  ),
                                                  tag: "a",
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      "text-decoration":
                                                        "unset",
                                                    },
                                                    attrs: { color: "primary" },
                                                  },
                                                  [_vm._v("mdi-file-link")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "form-display-text form-display-link",
                                                    staticStyle: {
                                                      color: "unset",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item[
                                                            "consignmentInstanceId"
                                                          ]
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Events",
                            },
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.eventTableHeaders,
                                items: _vm.formDataF["containerEvents"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.eventId",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "form-display-div" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: _vm.showEventLink(
                                                    item["eventId"]
                                                  ),
                                                  tag: "a",
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      "text-decoration":
                                                        "unset",
                                                    },
                                                    attrs: { color: "primary" },
                                                  },
                                                  [_vm._v("mdi-file-link")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "form-display-text form-display-link",
                                                    staticStyle: {
                                                      color: "unset",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item["eventId"]
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("asyent-toc"),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }