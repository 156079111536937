import { render, staticRenderFns } from "./containers-form.vue?vue&type=template&id=56d5a33e&scoped=true&"
import script from "./containers-form.vue?vue&type=script&lang=js&"
export * from "./containers-form.vue?vue&type=script&lang=js&"
import style0 from "./containers-form.vue?vue&type=style&index=0&id=56d5a33e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d5a33e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDataTable,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-workspace\\asyhub-platform-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56d5a33e')) {
      api.createRecord('56d5a33e', component.options)
    } else {
      api.reload('56d5a33e', component.options)
    }
    module.hot.accept("./containers-form.vue?vue&type=template&id=56d5a33e&scoped=true&", function () {
      api.rerender('56d5a33e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/cargo/pages/containers/containers-form.vue"
export default component.exports